//*******************************************************************************************/
//************** variables/buttons.scss - the starting file for global-defaults ****************/
//*******************************************************************************************/

$button-color: $brand-primary-color;
$button-color-hover: $brand-secondary-color;
$button-color-disabled: $light-grey3;
$button-background-color: $brand-secondary-color;
$button-background-color-hover: $brand-primary-color;
$button-background-color-disabled: $light-grey;
$button-border-width: 2px;
$button-border: $button-border-width solid $brand-secondary-color;
$button-border-disabled: 2px solid rgb(246, 246, 246);
$button-border-hover: 2px solid $brand-primary-color;
$button-border-radius: 0px;
$button-font-family: $branded-font-stack;
$button-font-size: 16px;
$button-font-weight: 700;
$button-height: 54px;
$button-padding: 0 1.5rem;
$button-margin: 20px auto;
$button-text-align: center;

$button-secondary-color: $brand-primary-color;
$button-secondary-color-hover: rgb(246, 246, 246);
$button-secondary-color-disabled: $light-grey;
$button-secondary-background-color: transparent;
$button-secondary-background-color-hover: $brand-primary-color;
$button-secondary-border: 2px solid $brand-primary-color;
$button-secondary-border-hover: 2px solid $brand-primary-color;
$button-secondary-border-disabled: 2px solid $light-grey;
