$shop-font-family: inherit !default;
$points-date-font-size: 11px;
$points-description-font-size: 14px;
$merchandise-title-font-size: 16px;
$points-balace-text-font-size: 24px;
$points-balace-font-size: 38px;

.main-nav {
  .icon {
    text-align: left;
  }

  .subnav {
    position: fixed;
    width: 100%;
    top: 0;
    height: calc(100vh - $dock-height-mobile);
    background-color: $brand-primary-color;
    left: inherit;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 100;

    .anchor-button {
      margin-right: 15px;
      margin-top: 15px;
    }

    &[aria-hidden="true"] {
      display: none;
    }

    @media screen and (min-width: 768px) {
      width: auto;
      height: calc(100vh - $dock-height-mobile - 30px);
      top: 30px;
      border-top: 1.5rem solid $brand-secondary-color;
      box-shadow: 15px 0 25px -15px $med-dark-grey;
      margin-right: 0;
      margin-top: 0;
    }

    @media screen and (min-width: 1024px) {
      top: 90px;
      height: calc(100vh - 90px);
    }

    > div {
      // the first div inside the subnav also needs the background color set
      height: calc(100vh - 157px); // header + dock => 64px + 69px = 133px
    }

    .subnav-user-info {
      background-color: $brand-primary-color;
      height: 11rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .points-display {
        border: none;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        order: 1;

        .balance {
          text-align: left;

          a {
            text-align: left;
            margin: 0;
            padding: 0;
            font-size: 13px;
            &:hover {
              background-color: initial;
            }
          }
          & * {
            color: $white !important; // all text in avatar container
          }
          &:before {
            display: none; // star with circle icone
          }
          .profile-user-name {
            display: block;
            margin-bottom: 0.5rem;
            span {
              display: block;
              line-height: 1.25;
            }
          }

          .val {
            font-size: 21px;
            &:after {
              content: ' ';
              width: 2px;
              display: inline-block;
            }
          }
        }
      }

      .avatar {
        width: 100px;
        height: 100px;
        border: 5px solid $white;
        border-radius: 55px;
        box-sizing: border-box;
        a {
          height: 100%;
          width: 100%;
          &:hover {
            background: none;
          }
          &:link {
            padding: 0;
          }
        }
      }

      .balance {
        a {
          min-width: auto;
        }
      }
    }
    a.nav-list-item-link {
      color: $brand-secondary-color;
      &:hover {
        color: $brand-primary-color;
      }
      &.active {
        background-color: #FFFFFF;
        color: $brand-primary-color;
      }
    }

    ul {
      box-shadow: none;
      width: 100%;
      background-color: $brand-primary-color;
      overflow-y: auto;

      @media screen and (min-width: 768px) {
        width: 295px;
      }

      li {
        padding: 3px; // So outline will display when keyboard focused
      }
    }
    * + * {
      border-top: 0;
    }

    a,
    a:link {
      font-size: 16px;
      padding: 0.8125rem;
      height: auto;
      width: 100%;

      &:hover {
        .icon {
          color: #000000;
        }
      }
      &:hover {
        background-color: #ffffff;
      }
    }
  }

  // My changes
  .subnav-rewards {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - $dock-height-mobile);
    top: 0;
    left: 0;
    padding: 20px 0;
    margin: 0;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 15px 0 25px -15px $med-dark-grey;
    overflow-x: hidden;
    overflow-y: hidden;

    &[aria-hidden="true"] {
      display: none;
    }

    .anchor-button {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 0.5rem;

      @media screen and (min-width: 768px) {
        top: 20px;
      }
    }

    @media screen and (min-width: 768px) {
      // this is when the top menu changes
      position: absolute;
      width: 444px;
      top: -460px;
      left: -200px;
      border-radius: 8px;
      height: 443px;
      @include subtle-box-shadow;
    }


    @media screen and (min-width: 1024px) {
      // switch to desktop layout
      left: -200px;
      right: 0px;
      top: 76px;
      height: auto;
      overflow-x: visible;
      overflow-y: visible;
      background-color: transparent;
      padding: 0;
    }

    &:has(.rewards-merchandise) {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: 543px;
        top: -560px;
      }
    }

    .rewards-title {
      font-size: 14px;
      line-height: 1.25;
      letter-spacing: 0;
      height: 21px;
      text-align: left;
      color: #101010;
    }
  }

  .root-rewards {
    padding: 0 24px;

    @media screen and (min-width: 768px) {
      width: 444px;
    }

    @media screen and (min-width: 1024px) {
      padding: 1px 39px 10px 33px;
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: -15px 6px 25px -15px $reward-box-shadow;
      border-radius: 8px 8px 8px 8px;
      height: auto;
    }

    .rewards-merchandise {
      position: relative;

      .rewards-title {
        position: absolute;
        top: 16px;
        left: 0;
        z-index: 1;
      }
    }
  }

  .points-container {
    width: 396px;
    height: auto;
    opacity: 1;
    margin: 80px 24px 0 24px;

    @media screen and (min-width: 768px)  {
      margin: 10px 0 0 0;
    }

    .points-section {
      margin: 16px 0;
      text-align: left;
      height: 76px;

      .icon1 {
        width: 22px;
        height: 22px;
        margin-right: 9px;
        float: left;
        background-image: url('../../../assets/img/Coins_Icn.svg');
        opacity: 1;
        top: 80px;
        margin-top: 3px;
      }

      .points-title-section {
        width: 80%;
        float: left;
        position: absolute;

        .points-title {
          /* font: normal normal normal 14px/20px "adelle-sans",sans-serif; */
          font-family: $shop-font-family;
          font-size: $points-description-font-size;
          letter-spacing: 0px;
          height: 21px;
          text-align: left;
          color: #101010;
          opacity: 1;
        }

        .points-date {
          height: 14px;
          /* font: normal normal normal 11px/14px "adelle-sans",sans-serif; */
          font-family: $shop-font-family;
          font-size: $points-date-font-size;
          letter-spacing: 0px;
          color: $header-font-color;
          margin-bottom: 21.5px;
        }
      }

      .points-balance {
        height: 65px;
        text-align: left;
        /* font: normal normal bold 38px/52px "adelle-sans",sans-serif; */
        font-family: $shop-font-family;
        font-size: $points-balace-font-size;
        letter-spacing: 0px;
        color: $brand-primary-color;

        .points-balance-text {
          /* font: normal normal normal 24px/32px "adelle-sans",sans-serif; */
          font-family: $shop-font-family;
          font-size: $points-balace-text-font-size;
          letter-spacing: 0px;
          padding-left: 6px;
          color: $brand-primary-color;
        }
      }
    }

    .points-desc-section {
      margin: 0;
      text-align: left;

      @media screen and (min-width: 768px) {
        margin-top: 20px;
      }

      .icon2 {
        width: 24px;
        height: 24px;
        opacity: 1;
        top: 80px;
        justify-content: center;
        margin-right: 6px;
        float: left;
        fill: $brand-primary-color;
      }

      .points-desc-wrap {
        width: 100%;
        text-align: left;
        /* font: normal normal normal 14px/18px "adelle-sans",sans-serif; */
        font-family: $shop-font-family;
        font-size: $points-description-font-size;
        letter-spacing: 0px;
        color: #101010;
        opacity: 1;

        @media screen and (min-width: 768px) {
          padding-top: 4px;
        }
      }
    }
  }

  .rewards-line {
    height: 1px;
    background: #C9D1DF;
    margin: 16px 0;

    @media screen and (min-width: 768px) {
      width: 396px;
    }
  }

  .rewards-menus {
    margin: 0 0 24px 0;
    padding: 1px 0px 0px 0px;

    > ul {
      padding: 0px;

      > li {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        list-style-type: none;
        margin: 14px 0px 20px 0px;

        .nav-list-item-link {
          position: relative;
          width: auto;
          height: auto;
          justify-content: normal;
          align-items: normal;

          .Merchandise-title {
            /* font: normal normal bold 16px/24px "adelle-sans",sans-serif; */
            font-family: $shop-font-family;
            font-size: $merchandise-title-font-size;
            letter-spacing: 0;
            color: #000000;
            width: 92px;
            display: inline-table;
            height: 21px;
            margin: -57px 49px 7px 65px;
            text-align: left;

            @media screen and (min-width: 768px) {
              display: block;
              width: 304px;
              margin: -56px 0px 8px 62px;
            }
          }

          .Merchandise-description {
            /* font: normal normal normal 14px/20px "adelle-sans",sans-serif; */
            font-family: $shop-font-family;
            font-size: $points-description-font-size;
            letter-spacing: 0;
            align-self: stretch;
            color: #000000;
            margin: 0 30px 0 66px;
            text-align: left;

            @media screen and (min-width: 768px) {
              margin: 0 30px 0 62px;
            }
          }

          .arrow {
            position: absolute;
            top: 13px;
            right: 0;
            background: url('../../../assets/img/link-external.svg');
            width: 24px;
            height: 24px;
          }

          .Experiences-title {
            /* font: normal normal bold 16px/24px "adelle-sans",sans-serif; */
            font-family: $shop-font-family;
            font-size: $merchandise-title-font-size;
            letter-spacing: 0;
            color: #000000;
            text-align: left;
            width: 92px;
            display: inline-table;
            margin: -59px 4px 6px 65px;

            @media screen and (min-width: 768px) {
              display: block;
              width: 304px;
              margin: -56px 0px 8px 62px;
            }
          }

          .Experiences-description {
            /* font: normal normal normal 14px/20px "adelle-sans",sans-serif; */
            font-family: $shop-font-family;
            font-size: $points-description-font-size;
            letter-spacing: 0;
            align-self: stretch;
            color: #000000;
            margin: 0 30px 0 64px;
            text-align: left;

            @media screen and (min-width: 768px) {
              margin: 0 30px 0 62px;
            }
          }

          .Mastery-title {
            /* font: normal normal bold 16px/24px "adelle-sans",sans-serif; */
            font-family: $shop-font-family;
            font-size: $merchandise-title-font-size;
            letter-spacing: 0;
            color: #000000;
            display: inline-table;
            width: 92px;
            margin: -55px 30px 6px 65px;
            text-align: left;

            @media screen and (min-width: 768px) {
              display: block;
              width: 304px;
              margin: -56px 0px 6px 62px;
            }
          }

          .Mastery-description {
            /* font: normal normal normal 14px/20px "adelle-sans",sans-serif; */
            font-family: $shop-font-family;
            font-size: $points-description-font-size;
            letter-spacing: 0;
            align-self: stretch;
            color: #000000;
            margin: 0 30px 0 65px;
            text-align: left;

            @media screen and (min-width: 768px) {
              margin: 0 30px 0 62px;
            }
          }
        }

        .img {
          width: 44px;
          height: 44px;
          cursor: pointer;
          margin: 8px 0 10px 6px;
        }

        @include marketplace-images;
      }
    }
  }
  ////
}
.subnav-overlay {
  //background-color: rgba(0, 0, 0, 0.5);
  //width: 100vw;
  //position: fixed;
  right: 0;
  top: 64px;
  height: calc(100vh - 133px); // 64px header and 69px dock for mobile
  // z-index: -1;
  @media screen and (min-width: 768px) {
    // tablet
    top: 80px;
    height: calc(100vh - 144px); // 80px header and 69px dock
  }
  @media screen and (min-width: 1024px) {
    // when on desktop move overlay to the left
    left: 0;
    height: calc(100vh - 80px); // 80px header
    position: absolute;
  }
}

.reward-arrow {
  display: none;
  position: absolute;
  top: -12px;
  width: 100%;
  height: 12px;
  background-color: transparent;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.reward-arrow::before{
  @media screen and (min-width: 1024px) {
    content: "";
    position: absolute;
    right: 190px;
    top: -7px;
    width: 12px;
    height: 6px;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 9999;
  }
}

@media screen and (max-width:480px) {
  .main-nav .subnav a, .main-nav .subnav a:link{
    padding: 0.8rem !important;
  }
}
