$avatar-height: 55px !default;
$avatar-width: 55px !default;
$avatar-color: $brand-primary-color !default;
$avatar-background-color: $brand-secondary-color !default;
$avatar-border-radius: 27.5px !default;
$avatar-font-family: inherit !default;
$avatar-font-size: 15px !default;
$avatar-font-weight: normal !default;
$avatar-line-height: inherit !default;
$avatar-margin: 0px 0px 0px 0px !default;

$balance-color: $header-font-color !default;
$balance-font-family: inherit !default;
$balance-font-weight: normal !default;
$balance-font-size: 16px !default;
$balance-line-height: 1 !default;
$balance-padding: 0px !default;
$balance-margin: 0px 15px 0px 0px !default;
$balance-text-align: center !default;

$balance-value-color: $header-font-color !default;
$balance-value-font-family: inherit !default;
$balance-value-font-weight: normal !default;
$balance-value-font-size: 30px !default;
$balance-value-line-height: 1 !default;
$balance-value-padding: 0px !default;
$balance-value-margin: 0px !default;

.points-display {
  .avatar {
    float: right;
    overflow: hidden;
    text-align: center;
    color: $avatar-color;
    border-radius: $avatar-border-radius;
    width: $avatar-width;
    height: $avatar-height;
    margin: $avatar-margin;

    a {
      text-decoration: none;
      cursor: pointer;
      display: block;
      height: 100%;
      width: 100%;
      color: $avatar-color;
    }

    .initials {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $avatar-font-family;
      font-size: $avatar-font-size;
      font-weight: $avatar-font-weight;
      line-height: $avatar-line-height;
      background-color: $avatar-background-color;
      border-radius: $avatar-border-radius;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .balance {
    display: inline-block;
    color: $balance-color;
    font-family: $balance-font-family;
    font-weight: $balance-font-weight;
    font-size: $balance-font-size;
    line-height: $balance-line-height;
    padding: $balance-padding;
    margin: $balance-margin;
    text-align: $balance-text-align;

    .profile-user-name {
      display: none;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      color: $balance-color;
    }

    .val {
      color: $balance-value-color;
      font-family: $balance-value-font-family;
      font-weight: $balance-value-font-weight;
      font-size: $balance-value-font-size;
      line-height: $balance-value-line-height;
      padding: $balance-value-padding;
      margin: $balance-value-margin;
    }
  }

  @media screen and (max-width: 1023px) {
    transition: 100ms opacity;

    &.visible-0 {
      opacity: 0;
    }

    &.visible-10 {
      opacity: 0;
    }

    &.visible-20 {
      opacity: 0;
    }

    &.visible-30 {
      opacity: 0;
    }

    &.visible-40 {
      opacity: 0.4;
    }

    &.visible-50 {
      opacity: 0.5;
    }

    &.visible-60 {
      opacity: 0.6;
    }

    &.visible-70 {
      opacity: 0.7;
    }

    &.visible-80 {
      opacity: 0.8;
    }

    &.visible-90 {
      opacity: 0.9;
    }

    &.visible-100 {
      opacity: 1;
    }
  }
}
