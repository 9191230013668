.site-content > .content-wrapper .content {
  &.contact-us {
    @include content-container-fluid;
    
    .content-body {
      display: flex;

      @media screen and (max-width: 1023px) {
        flex-direction: column;
      }
    }

    .message-container {
      flex: 1;
      padding: 40px 180px 40px 0px;

      @media screen and (max-width: 1023px) {
        padding-right: 0px;
        button {
          width: 100%;
        }
      }

      .form-group.subject {
        label {
          & > span {
            display: inline-block;
            margin: 0px 10px 0px 0px;
          }
        }
      }

      .form-group.submit {
        padding-top: 10px;
      }

      .wizard-container {
        .step-list {
          display: none;
        }
      }
    }

    .chat-container {
      width: 262px;
      box-sizing: border-box;
      padding: 40px 20px;
      background: #f4f4f4;

      @media screen and (max-width: 1023px) {
        width: 100%;
        padding: 0px;

        button {
          width: 100%;
        }
      }

      p {
        margin: 0px 0px 20px 0px;
      }

      .form-group.chat {
        padding: 0px 10px;

        @media screen and (max-width: 1023px) {
          padding: 0px;
        }

        .button {
          width: 100%;
        }
      }
    }
  }
}
