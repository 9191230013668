$modal-overlay-color: rgba(0, 0, 0, 0.75);
$modal-overlay-margin: 0px;
$modal-overlay-margin-mobile: $header-height-mobile 0px $dock-height-mobile 0px;
$modal-overlay-padding: (60px + $header-height) 60px 60px 60px;
$modal-overlay-padding-mobile: 20px 20px 20px 20px;

$modal-color: black;
$modal-background-color: white;
$modal-padding: 60px;
$modal-padding-mobile: 30px;

$modal-close-color: white;
$modal-close-background-color: #737373;
$modal-close-border-style: none;
$modal-close-border-width: 0px;
$modal-close-border-color: transparent;
$modal-close-border-radius: 17px;
$modal-close-width: 34px;
$modal-close-height: 34px;
$modal-close-margin: 15px 15px 0px 0px;
$modal-close-padding: 0px;
$modal-margin-top: 0px;

$modal-transition-duration: 500ms;
$modal-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

@import '../../../app/q360-comps/modal-window/src/modal-window.scss';

$overlay-color: white;
$overlay-background-color: rgba(0, 0, 0, 0.85);
$overlay-padding: 60px;
$overlay-padding-mobile: 60px;

$overlay-header-color: $overlay-color;
$overlay-header-font-family: $branded-font-stack;
$overlay-header-font-size: 40px;
$overlay-header-font-weight: normal;
$overlay-header-line-height: inherit !default;
$overlay-header-margin: 0px 0px 20px 0px !default;

$overlay-text-color: $overlay-color;
$overlay-text-font-family: $branded-font-stack;
$overlay-text-font-size: 26px;
$overlay-text-font-weight: lighter;
$overlay-text-line-height: inherit !default;
$overlay-text-margin: 0px 0px 20px 0px !default;

$overlay-close-color: white;
$overlay-close-background-color: #737373;
$overlay-close-border-style: none;
$overlay-close-border-width: 0px;
$overlay-close-border-color: transparent;
$overlay-close-border-radius: 17px;
$overlay-close-width: 34px;
$overlay-close-height: 34px;
$overlay-close-margin: 15px 15px 0px 0px;
$overlay-close-padding: 0px;

$overlay-transition-duration: 300ms;
$overlay-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);

@import '../../../app/q360-comps/overlay/src/overlay.scss';

$takeover-padding: 100px 0px 0px 0px;

$takeover-transition-duration: 600ms;
$takeover-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$takeover-transition-content-delay: calc($takeover-transition-duration / 2);
$takeover-transition-content-duration: calc($takeover-transition-duration / 2);

@import '../../../app/q360-comps/takeover/src/takeover.scss';

// modals that pop up
.modal-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    button,
    .button {
      display: inline-block;
      margin: 0 auto;
    }
  }
}
