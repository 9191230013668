.total_points {
  position: absolute;
  top: 0;
  padding: 6px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 8px 0 #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  z-index: 1;

  .points {
    fill: $brand-primary-color;
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  .pts {
    font-size: 11px;
  }
}

div.tile.large
  div.promotion.board_with_block
  div.content.is-media-background-base.is-media-background-active {
  height: 100%;
  top: 0px;
  div.task-container.carousel {
    height: 100%;
    margin-top: 3%;
  }
}

.tile.small {
  transition: $tile-transition;
  height: 100%;
  max-width: 384px;
  border-radius: $small-tile-border-radius;

  .block-content__image {
    border-top-left-radius: $small-tile-border-radius;
    border-top-right-radius: $small-tile-border-radius;
  }

  .banner-promo-image {
    border-radius: $small-tile-border-radius;
  }

  .power-ups &, .archived-power-ups & {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  &:hover {
    box-shadow: $tile-box-shadow-hover;
  }
}

.tile.small .promotion > .is-media-background-base .task-container .task-slide-container .task-slide {
  margin: 12% 0 0 2%;
}

.tile.small
  .promotion.single_block
  > .is-media-background-base
  .task-container
  .task-slide-container
  .task-slide {
  margin: 0;

  .task {
    margin: 0;
    padding: 0 20px;
  }
}

.tile.large .promotion {
  &.banner .media > div {
    padding: 0;
  }
}

.welcome-section .tile .promotion {
  & > .is-media-background-base {
    .task-container {
      .task-slide-container {
        .task-slide {
          @media screen and (min-width: 1024px) {
            margin: 7% 3% 0;
            padding-top: 45px;
          }
        }
      }
    }
  }
}

.tile {
  .promotion {
    & > .media-main-active {
      transition: all 0.5s;
      // height: 320px is causing small tiles on mobile (580px wide) to become too big
      // @media screen and (max-width: 767px) {
      //     height: 320px;
      // }
      & > div {
        // @media screen and (max-width: 767px) {
        //     height: 320px;
        // }
        img {
          height: inherit;
          background-position-y: 0 !important;
        }
      }
    }

    &.banner .media > div {
      // padding: 1rem 0;
    }

    .cropping-window-challenge {
      @media screen and (max-width: 500px) {
        //height: 320px;
        .dashboard-img {
          width: auto;
        }
      }
      display: flex;
      justify-content: center;
    }

    // this is some funky stuff (see javascript - search "is-media-background"
    // & > .is-media-background-inactive {
    // }
    // & > .is-media-background-base {
    // }
    // could be .is-media-background-active
    & > .is-media-background-active {
      position: absolute;
      background-color: transparent;
      max-width: unset;
    }

    & > .is-media-background-base {
      transition: all 0.5s;
      top: 5%;

      .task-container {
        background: inherit;

        .task-slide-container {
          position: static;

          .task-slide {
            margin: 7% 3% 0;
            padding: 0;
            outline: 0;
          }
        }

        .task {
          background: inherit;
          margin-top: 80px;
          // height: 300px;

          @media screen and (max-width: 630px) {
            margin: 3% auto 0;
          }

          @media screen and (min-width: 768px) {
            margin: 7% auto 0;
          }

          @media screen and (min-width: 1024px) {
            margin: 7% auto 0;
          }

          @media screen and (min-width: 1440px) {
            margin: 5% auto;
          }

          div.complete.was-p-tag {
            display: none;
          }

          .description_complete {
            color: #fff;
            margin-bottom: 1rem;
            font-size: 13px;
            @media screen and (min-width: 1024px) {
              font-size: 1.3125rem;
              width: 48%;
            }
          }

          .success-checkmark {
            position: absolute;
            top: 8%;

            @media screen and (min-width: 768px) {
              top: 28%;
              right: 5%;
              width: 200px;
              height: 200px;
            }
            @media screen and (min-width: 1024px) {
              width: 240px;
              height: 240px;
              top: 42%;
            }

            @media screen and (min-width: 1440px) {
              top: 48%;
              right: 5%;
            }
          }

          @media screen and (min-width: 768px) {
            .check-complete,
            .description_complete {
              width: 60%;
            }
          }

          // set default font-size
          .title,
          .title.complete-title,
          .check-complete .title {
            color: #fff;
            font-weight: 400;
            font-size: 39px;

            @media screen and (max-width: 768px) {
              font-size: 19px;
            }
            @media screen and (min-width: 768px) and (max-width: 1024px) {
              font-size: 39px;
            }
          }

          .title-resume {
            font-size: 0.8125rem;
          }

          .title-on-resume {
            margin: 0;
            color: #fff;
            font-weight: 400;
            font-size: 39px;
            width: 65%;

            @media screen and (max-width: 768px) {
              font-size: 19px;
            }
            @media screen and (min-width: 768px) and (max-width: 1024px) {
              font-size: 37px;
            }
          }

          .text,
          .description,
          .description_complete {
            color: #fff;
            margin: 0 0 1rem 0;
            font-size: 0.8125rem;
            font-weight: 200;
            margin-top: 0.5rem;
            @media screen and (min-width: 768px) {
              font-size: 1rem;
            }

            @media screen and (min-width: 1024px) {
              font-size: 1.3125rem;
            }
          }

          // .check-complete {
          //   @media screen and (min-width: 500px) and (max-width: 550px) {
          //     margin-top: 20%;
          //   }
          // }

          &.board_with_block {
            .progress-bar {
              @media screen and (min-width: 768px) {
                max-width: 345px;
              }
              @media screen and (min-width: 1024px) {
                max-width: 645px;
              }

              .status {
                margin-bottom: 0.5rem;
              }

              .react-sweet-progress-symbol {
                display: none;
              }

              .bonus-section {
                padding-top: 0.5rem;
                position: relative;
                margin-bottom: -2rem;

                @media screen and (min-width: 768px) {
                  margin-bottom: 0;
                }

                p,
                .bonusPts {
                  font-size: 11px;
                  @media screen and (min-width: 768px) {
                    font-size: 13px;
                  }
                  @media screen and (min-width: 1024px) {
                    font-size: 16px;
                  }
                }

                .bonusPts1,
                .bonusPts2 {
                  padding-right: 2rem;
                }

                .bonusPts1::after,
                .bonusPts2::after {
                  content: '';
                  height: 1.25rem;
                  border-right: 1px solid #ccc;
                  position: absolute;
                  top: 0;
                  padding-left: 0.75rem;
                  @media screen and (min-width: 768px) {
                    height: 1.75rem;
                  }
                }

                .success-check {
                  width: 12px;
                  height: 12px;
                  margin-right: 4px;
                  fill: $brand-primary-color;

                  @media screen and (min-width: 768px) {
                    vertical-align: baseline;
                  }
                }
              }
            }

            .stats {
              display: flex;
              margin-bottom: 1rem;

              @media screen and (max-width: 786px) {
                margin-bottom: 0rem;
              }
              @media screen and (max-width: 630px) {
                flex-direction: column;
              }
            }

            p.status {
              display: inline-block;
              color: #fff;
              text-align: left;
              margin: 0 15px 0 0;
              font-size: 32px;

              @media screen and (max-width: 630px) {
                font-size: 19px;
              }

              .of,
              .total {
                font-weight: 200;
              }

              .activities {
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                margin: 0 0 0 0.2rem;
              }
            }
          }
        }
      }

      &::after {
        display: none;
      }
    }

    &.banner {
      text-align: center;
    }

    // Without the following style the survey under archived powerups is screwed up
    &.single_block.banner {
      text-align: left;
    }
  }

  // Custom positioning of text in Large News/Comm blocks to display in the bottom half of background image.
  &.large {
    .promotion {
      &.news_communications_block {
        & > .is-media-background-base {
          top: 0;
          height: 100%;

          .carousel {
            height: 100%;

            .task-slide-container {
              height: 100%;

              &.error {
                display: none;
              }

              .task-slide {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-top: 0;
                padding: 0;

                .task {
                  margin: 0;
                  min-height: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tile.large .form-group.submit .button span {
  padding: 0;
  &::before,
  &::after {
    display: none;
  }
}

.tile {
  background-color: transparent;
  display: flex;
  flex-direction: column;

  &.promotion-banner {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    .banner-promo-image {
      box-shadow: $tile-box-shadow;
      transition: $tile-transition;

      &:hover {
        box-shadow: $tile-box-shadow-hover;
      }
    }
  }
}

.meter-component.radial-meter {
  .percent {
    font-size: 0.35px;
  }
  &.challenge-progress {
    position: absolute;
    right: 0rem;
    top: 7rem;
    width: 18rem;

    @media screen and (max-width: 560px) {
      top: 0rem;
      width: 9rem;
    }

    @media screen and (min-width: 561px) and (max-width: 667px) {
      top: 5rem;
      width: 11rem;
    }

    @media screen and (min-width: 668px) and (max-width: 767px) {
      top: 5rem;
      width: 12rem;
    }

    @media screen and (min-width: 768px) and (max-width: 819px) {
      top: 6rem;
      width: 14rem;
    }

    @media screen and (min-width: 820px) and (max-width: 1024px) {
      top: 4rem;
      width: 15rem;
    }

    @media screen and (min-width: 1025px) {
      top: 5rem;
      width: 15rem;
    }

    .meter-bg {
      fill: none;
      stroke-width: 0.14615px;
      stroke: #999;
    }
    .bar {
      stroke-width: 0.13615px;
      stroke: $brand-secondary-color;
    }
  }
}

.tile.large .promotion .is-media-background-active .form-group.submit {
  @media screen and (max-width: 786px) {
    margin: 0 !important;
  }
}

.tile.large .promotion .is-media-background-active .form-group.submit .button.go, .tile.large .promotion .is-media-background-active .form-group.submit .go.secondary-btn {
  @media screen and (max-width: 560px) {
    margin: 0;
    padding: 0.325rem;
    width: auto;
    height: auto;
  }
}
