$focus-outline: none !default;
$focus-box-shadow: 0 0 3px 1px $focus-color !default;

$board-tile-description-color: #000000 !default;
$board-tile-description-font-family: inherit !default;
$board-tile-description-font-size: 15px !default;
$board-tile-description-font-weight: normal !default;
$board-tile-description-line-height: inherit !default;
$board-tile-description-padding: 0px !default;
$board-tile-description-margin: 20px 0px 0px 0px !default;

$board-tile-status-color: #000000 !default;
$board-tile-status-font-family: inherit !default;
$board-tile-status-font-size: 48px !default;
$board-tile-status-font-weight: bold !default;
$board-tile-status-line-height: inherit !default;
$board-tile-status-padding: 0px !default;
$board-tile-status-margin: 20px 0px 20px 0px !default;

$board-tile-complete-color: #000000 !default;
$board-tile-complete-font-family: inherit !default;
$board-tile-complete-font-size: 13px !default;
$board-tile-complete-font-weight: normal !default;
$board-tile-complete-line-height: inherit !default;
$board-tile-complete-padding: 0px !default;
$board-tile-complete-margin: 0px 0px 10px 0px !default;

$board-color: black !default;
$board-background-color: white !default;
$board-padding: 10px 20px 20px 20px !default;
$board-padding-mobile: 10px 10px 10px 10px !default;

$board-header-color: $board-color !default;
$board-header-font-family: inherit !default;
$board-header-font-size: 30px !default;
$board-header-font-weight: bold !default;
$board-header-line-height: inherit !default;
$board-header-padding: 20px 20px 0px 20px !default;
$board-header-margin: 0px 0px 5px 0px !default;

$board-text-color: $board-color !default;
$board-text-font-family: inherit !default;
$board-text-font-size: 15px !default;
$board-text-font-weight: normal !default;
$board-text-line-height: inherit !default;
$board-text-padding: 0px 20px 0px 20px !default;
$board-text-margin: 0px !default;

$board-status-color: $board-color !default;
$board-status-font-family: inherit !default;
$board-status-font-size: 18px !default;
$board-status-font-weight: normal !default;
$board-status-line-height: inherit !default;
$board-status-padding: 0px 20px 0px 20px !default;
$board-status-margin: 20px 0px 0px 0px !default;

$board-status-out-of-color: gray !default;
$board-status-out-of-font-family: inherit !default;
$board-status-out-of-font-size: 18px !default;
$board-status-out-of-font-weight: bold !default;
$board-status-out-of-line-height: inherit !default;
$board-status-out-of-padding: 0px !default;
$board-status-out-of-margin: 20px 0px 0px 0px !default;

$board-media-margin: 0px 0px 10px 0px !default;
$board-blocks-margin: -5px -25px -5px -5px !default;

$block-color: #ededee !default;
$block-background-color: #b6b6b6 !default;
$block-border-color: $block-background-color !default;
$block-border-width: 5px !default;
$block-border-style: solid !default;
$block-border-radius: 0px !default;
$block-font-family: inherit !default;
$block-font-size: 16px !default;
$block-font-weight: normal !default;
$block-line-height: inherit !default;
$block-height: 108px !default;
$block-width: calc(33% - 14px) !default;
$block-padding: 40px 12px 12px 12px !default;
$block-padding-mobile: 10px !default;
$block-margin: 5px !default;

$block-status-color: white !default;
$block-status-background-color: #888888 !default;
$block-status-border-style: none !default;
$block-status-border-color: transparent !default;
$block-status-border-width: 0px !default;
$block-status-border-radius: 13px !default;
$block-status-padding: 2px !default;
$block-status-margin: 5px !default;
$block-status-margin-mobile: 0px 0px 0px 10px !default;
$block-status-width: 26px !default;
$block-status-height: 26px !default;

$block-icon-color: white !default;
$block-icon-background-color: #292929 !default;
$block-icon-font-family: inherit !default;
$block-icon-font-size: 28px !default;
$block-icon-font-weight: inherit !default;
$block-icon-line-height: 46px !default;
$block-icon-width: 46px !default;
$block-icon-height: 46px !default;
$block-icon-border-style: none !default;
$block-icon-border-width: 0px !default;
$block-icon-border-color: transparent !default;
$block-icon-border-radius: 23px !default;
$block-icon-padding: 0px !default;
$block-icon-margin: 0px 7px 0px 0px !default;

$block-active-border-color: black !default;
$block-active-color: inherit !default;
$block-active-background-color: inherit !default;
$block-active-status-color: inherit !default;
$block-active-status-background-color: inherit !default;
$block-active-status-border-color: inherit !default;
$block-active-icon-color: inherit !default;
$block-active-icon-background-color: transparent;
$block-active-icon-border-color: inherit !default;

$block-complete-color: #fefefe !default;
$block-complete-background-color: #7f7f7f !default;
$block-complete-border-color: $block-complete-background-color !default;
$block-complete-status-color: #ffffff !default;
$block-complete-status-background-color: #595959 !default;
$block-complete-status-border-color: $block-complete-status-background-color !default;
$block-complete-icon-color: $block-icon-color !default;
$block-complete-icon-background-color: $block-icon-background-color !default;
$block-complete-icon-border-color: $block-complete-icon-background-color !default;

$block-unlocked-color: #fefefe !default;
$block-unlocked-background-color: #b6b6b6 !default;
$block-unlocked-border-color: $block-unlocked-background-color !default;
$block-unlocked-status-color: #ffffff !default;
$block-unlocked-status-background-color: #878787 !default;
$block-unlocked-status-border-color: $block-unlocked-status-background-color !default;
$block-unlocked-icon-color: $block-icon-color !default;
$block-unlocked-icon-background-color: $block-icon-background-color !default;
$block-unlocked-icon-border-color: $block-unlocked-icon-background-color !default;

$block-locked-color: #eeeeef !default;
$block-locked-background-color: #dadada !default;
$block-locked-border-color: $block-locked-background-color !default;
$block-locked-status-color: #ffffff !default;
$block-locked-status-background-color: #a9a9a9 !default;
$block-locked-status-border-color: $block-locked-status-background-color !default;
$block-locked-icon-color: #ffffff !default;
$block-locked-icon-background-color: #b3b3b3 !default;
$block-locked-icon-border-color: $block-locked-icon-background-color !default;

$block-transition-duration: 300ms !default;
$block-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

$board-task-padding: 10px 20px 20px 20px !default;
$board-task-padding-mobile: 10px 20px 20px 20px !default;

$block-back-color: #000000 !default;
$block-back-font-family: inherit !default;
$block-back-font-size: 18px !default;
$block-back-font-weight: bold !default;
$block-back-line-height: inherit !default;
$block-back-margin: 0px 0px 20px 0px !default;

$task-header-color: black !default;
$task-header-font-family: $task-font-family !default;
$task-header-font-size: 30px !default;
$task-header-font-weight: normal !default;
$task-header-line-height: inherit !default;
$task-header-margin: 0px !default;

$promotion-media-aspect-ratio: percentage(9/16) !default; //9:16 = percentage(9/16);
$promotion-media-transition-duration: 300ms !default;
$promotion-media-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

/* Tile */
.task {
  &.board_with_block {
    p {
      &.description {
        color: $board-tile-description-color;
        font-family: $board-tile-description-font-family;
        font-size: $board-tile-description-font-size;
        font-weight: $board-tile-description-font-weight;
        line-height: $board-tile-description-line-height;
        padding: $board-tile-description-padding;
        margin: $board-tile-description-margin;
        text-align: left;
      }

      &.status {
        color: $board-tile-status-color;
        font-family: $board-tile-status-font-family;
        font-size: $board-tile-status-font-size;
        font-weight: $board-tile-status-font-weight;
        line-height: $board-tile-status-line-height;
        padding: $board-tile-status-padding;
        margin: $board-tile-status-margin;
        text-align: center;
      }

      span {
        &.complete {
          display: block;
          font-family: $board-tile-complete-font-family;
          font-size: $board-tile-complete-font-size;
          font-weight: $board-tile-complete-font-weight;
          line-height: $board-tile-complete-line-height;
          padding: $board-tile-complete-padding;
          margin: $board-tile-complete-margin;
        }
      }
    }
  }
}

@mixin plan-block-styles {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-height: $block-height;
  color: $block-unlocked-color;
  background-color: $block-unlocked-background-color;
  border-color: $block-unlocked-border-color;
  border-style: $block-border-style;
  border-width: $block-border-width;
  border-radius: $block-border-radius;
  font-family: $block-font-family;
  font-size: $block-font-size;
  font-weight: $block-font-weight;
  line-height: $block-line-height;
  margin: $block-margin;
  padding: $block-padding;
  text-align: left;

  @media screen and (min-width: 300px) {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: $block-padding-mobile;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  &:focus {
    outline: $focus-outline;
    box-shadow: $focus-box-shadow;
  }

  &.complete {
    color: $block-complete-color;
    background-color: transparent;
    border-color: $block-complete-border-color;

    .status {
      color: $block-complete-status-color;
      background-color: $block-complete-status-background-color;
      border-color: $block-complete-status-border-color;

      .icon {
        fill: $block-complete-icon-color;
      }
    }
  }

  &.locked {
    color: $block-locked-color;
    background-color: $block-locked-background-color;
    border-color: $block-locked-border-color;

    .status {
      color: $block-locked-status-color;
      background-color: $block-locked-status-background-color;
      border-color: $block-locked-status-border-color;

      .icon {
        fill: $block-locked-icon-color;
      }
    }
  }

  &.active {
    border-color: $block-active-border-color;

    // all the rest of these variables in this .active block are defaulted to inherit, so shouldn't do anything. They could be commented out.
    color: $block-active-color;
    background-color: $block-active-background-color;

    .status {
      color: $block-active-status-color;
      background-color: $block-active-status-background-color;
      border-color: $block-active-status-border-color;

      .icon {
        fill: $block-active-icon-color;
      }
    }
  }

  & > div {
    height: 100%;

    & > div.text {
      display: flex;
      align-items: center;
      // max-height: $block-icon-height;

      @media screen and (min-width: 300px) {
        flex: 1;
      }
    }
  }

  .status {
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: $block-status-margin;
    width: $block-status-width;
    height: $block-status-height;
    color: $block-unlocked-status-color;
    background-color: $block-unlocked-status-background-color;
    border-style: $block-status-border-style;
    border-color: $block-unlocked-status-border-color;
    border-width: $block-status-border-width;
    border-radius: $block-status-border-radius;
    padding: $block-status-padding;

    @media screen and (min-width: 300px) {
      position: static;
      margin: $block-status-margin-mobile;
      order: 1;
    }

    svg {
      fill: $block-complete-icon-color;
      width: 100%;
      height: 100%;
    }
  }

  .name {
    display: inline-block;
  }
}

.plan-block {
  @include plan-block-styles;
}

/* Page */
.tile.standalone {
  .board-container {
    position: relative;
    overflow: hidden;

    .board-header {
      color: $board-color;
      background-color: $board-background-color;
      position: relative;

      h1,
      .header-title {
        color: $board-header-color;
        font-family: $board-header-font-family;
        font-size: $board-header-font-size;
        font-weight: $board-header-font-weight;
        line-height: $board-header-line-height;
        padding: $board-header-padding;
        margin: $board-header-margin;
      }

      .header-text {
        color: $board-text-color;
        font-family: $board-text-font-family;
        font-size: $board-text-font-size;
        font-weight: $board-text-font-weight;
        line-height: $board-text-line-height;
        padding: $board-text-padding;
        margin: $board-text-margin;
      }

      .status {
        color: $board-status-color;
        font-family: $board-status-font-family;
        font-size: $board-status-font-size;
        font-weight: $board-status-font-weight;
        line-height: $board-status-line-height;
        padding: $board-status-padding;
        margin: $board-status-margin;

        span {
          &.completed,
          &.of,
          &.total {
            color: $brand-primary-color;
            font-family: $board-status-out-of-font-family;
            font-size: $board-status-out-of-font-size;
            font-weight: $board-status-out-of-font-weight;
            line-height: $board-status-out-of-line-height;
            padding: $board-status-out-of-padding;
            margin: $board-status-out-of-margin;
          }
        }
      }
    }

    .promotion {
      &.board_with_block {
        position: relative;

        .board {
          color: $board-color;
          background-color: $board-background-color;
          padding: $board-padding;
          position: relative;

          @media screen and (min-width: 300px) {
            padding: $board-padding-mobile;
          }

          & > .media {
            margin: $board-media-margin;
            display: none;

            & > div {
              position: relative;
              width: 100%;
              padding-bottom: $promotion-media-aspect-ratio; // aspect ratio
              overflow: hidden;

              & > div {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
              }

              img,
              video {
                width: 100%;
                height: auto;
                vertical-align: middle;
              }

              .video-container {
                position: absolute;
                left: 0px;
                top: 0px;
                right: 0px;
                bottom: 0px;
                display: flex;
                background-color: black;
              }
            }

            .media-animate-enter {
              opacity: 0;
              transition: $promotion-media-transition-duration $promotion-media-transition-easing all;
              position: relative;
            }

            .media-animate-enter.media-animate-enter-active {
              opacity: 1;
              position: relative;
            }

            .media-animate-exit {
              opacity: 1;
              transition: $promotion-media-transition-duration $promotion-media-transition-easing all;
              position: relative;

              & > * {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
              }
            }

            .media-animate-exit.media-animate-exit-active {
              opacity: 0;
              position: relative;
            }
          }

          .blocks {
            margin: $board-blocks-margin;
            display: flex;
            flex-flow: row wrap;

            @media screen and (min-width: 300px) {
              margin: 0px;
            }

            .block {
              @include plan-block-styles;
            }
          }
        }

        .block-container {
          position: relative;
          width: 100%;
          //margin-bottom: 20px;

          .block-animate-enter {
            opacity: 0;
            transition: $block-transition-duration $block-transition-easing all;
          }

          .block-animate-enter.block-animate-enter-active {
            opacity: 1;
          }

          .block-animate-exit {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            opacity: 1;
            transition: $block-transition-duration $block-transition-easing all;
          }

          .block-animate-exit.block-animate-exit-active {
            opacity: 0;
          }

          .task-container {
            .task-slide-container {
              .task-slide {
                padding: $board-task-padding;

                @media screen and (min-width: 300px) {
                  padding: $board-task-padding-mobile;
                }
              }
            }
          }

          .block-header {
            display: none;
            padding: 20px 20px 0px 20px;

            @media screen and (min-width: 300px) {
              display: block;
            }

            .split-screen-container {
                overflow: auto;

              & > .title {
                margin-top: 44px !important;
                font-family: $task-header-font-family;
                // font-size: $task-header-font-size;
                font-weight: $task-header-font-weight;
                line-height: $task-header-line-height;
                margin: $task-header-margin;
                line-height: 1.2;

                // Standard Font size
                font-size: 42px;
                @media screen and (max-width: 768px) {
                  font-size: 21px;
                }
                @media screen and (min-width: 768px) and (max-width: 1024px) {
                  font-size: 34px;
                }
              }

              & > .media {
                background-color: #f1f2f2;
                // margin: 10px 0px 0px 0px;

                & > div {
                  position: relative;
                  width: 100%;
                  padding-bottom: $promotion-media-aspect-ratio; // aspect ratio

                  @media screen and (max-width: 1023px) {
                    overflow: hidden;
                  }

                  @media screen and (min-width: 1024px) {
                    height: 100%;
                  }

                  & > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                  }

                  img,
                  video {
                    width: 100%;
                    height: auto;
                    vertical-align: middle;
                    @media screen and (min-width: 1024px) {
                      height: 100%;
                    }
                  }

                  .video-container {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                  }
                }

                .media-animate-enter {
                  opacity: 0;
                  transition: $promotion-media-transition-duration $promotion-media-transition-easing all;
                  position: relative;
                }

                .media-animate-enter.media-animate-enter-active {
                  opacity: 1;
                  position: relative;
                }

                .media-animate-exit {
                  opacity: 1;
                  transition: $promotion-media-transition-duration $promotion-media-transition-easing all;
                  position: relative;

                  & > * {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    top: 0px;
                    bottom: 0px;
                  }
                }

                .media-animate-exit.media-animate-exit-active {
                  opacity: 0;
                  position: relative;
                }
              }
            }

            // mvd to global-overrides.scss ".task > h1.title, .task > div.text {"
            // @media screen and (min-width: 300px) {
            //     .task {
            //         & > .title {
            //             display: none;
            //         }
            //     }
            // }
          }
        }
      }
    }

    // @media screen and (min-width: 320px) {
    .promotion {
      &.board_with_block {
        width: 200%;
        flex-direction: row;
        transition: left linear 300ms;
      }

      & > .media,
      & > .content {
        display: inline-block;
        width: 50%;
      }

      .overlay-container {
        .overlay {
          left: 50%;
        }
      }
    }

    &.show-board {
      .promotion {
        &.board_with_block {
          left: 0px;
        }
      }
    }

    &.show-block {
      .promotion {
        &.board_with_block {
          left: -100%;
        }
      }
    }
    // }
  }
}

.show-board-main {
  .promotion {
    left: 0px;
  }
}
.show-block-main {
  .promotion {
    left: -100%;
  }
}

.board-container-main {
  position: relative;
}
