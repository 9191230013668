.content {
  // WARNING _profile.scss also applies styles to &.register too
  // which will override styles here because it comes after register.scss in theme.scss import
  &.register,
  &.profile {
    .form-group.application-models-user-user\[termsofuse\] {
      margin-top: 32px;
    }
    .form-group.application-models-user-user\[termsofuse\] > fieldset.checkbox > legend {
      border-top: 1px solid #bbbbbb;
      width: 100%;
      padding-top: 28px;
      padding-bottom: 10px;
    }

    .form-group {
      h3 {
        font-size: 21px;
      }
    }

    .wizard-container .step-list {
      display: none;
    }

    .wizard-content {
      width: 450px;
      padding: 0px 2px;
      margin: 0px auto;
      box-sizing: border-box;

      @media screen and (min-width: 1024px) {
        width: 100%;
        /* margin: 0px 10px; */
      }

      @media screen and (max-width: 1023px) {
        width: 100%;
        // padding: 20px;
      }

      @media screen and (min-width: 1440px) {
        margin: 0;
      }
    }

    .form-group {
      & span.description {
        font-size: 16px;
      }

      &.application-models-user-profile\[avatarimage\] {
        .image-input {
          .image-container {
            margin-left: auto;
            margin-right: auto;
          }

          & > label,
          & > span {
            width: 100%;
          }
        }
      }

      &.submit {
        margin: 0px !important;
        padding: 0px !important;

        button {
          margin-right: 12px;
          display: inline-flex;
          justify-content: center;
        }
      }
    }

    // MODAL WINDOW STYLE FOR REGISTRATION
    .modal {
      display: none;
      position: fixed;
      z-index: 25;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);

      &.modal-open {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal-content {
      background-color: #f4f4f4;
      padding: 20px;
      width: 40%;
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
      animation-name: modalopen;
      animation-duration: 1s;
      @media screen and (max-width: 1023px) {
        width: 80%;
      }
    }

    @keyframes modalopen {
      from {
        opacity: 0;
        transform: translateY(-100%);
      }
      to {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    .basic-header,
    .basic-header ~ p {
      margin: 0 auto;
      max-width: $content-wrapper-max-width;
      text-align: left;
      width: 75%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      @media screen and (min-width: 500px) {
        margin-left: 8%;
      }
      @media screen and (min-width: 630px) {
        margin-left: 6%;
      }
      @media screen and (min-width: 1024px) {
        margin-left: 4%;
      }
      @media screen and (min-width: 1440px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
      }
    }
    h1.basic-header {
      padding-top: 8px;
      @media screen and (max-width: 767px) {
        padding: 10px;
      }
      @media screen and (min-width: 768px) {
        padding-top: 20px;
      }
      @media screen and (min-width: 1024px) {
        padding-top: 24px;
      }
      @media screen and (min-width: 1440px) {
        margin-left: 0;
      }
    }
  }
  .wizard-container, .content.register .wizard-container {
    @media screen and (max-width: 767px) {
      padding: 0px !important;
    }
  }

  .require-field {
    line-height: 1.5;
    letter-spacing: -0.1px;
    padding: 0px 0px 25px 10px;
    color: rgb(99, 99, 99);
  }
}


