//*******************************************************************************************/
//*************** pages/main.scss - the starting file for global-defaults *******************/
//*******************************************************************************************/

@mixin top-tile {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1); // Altered from design document to reduce discrepancy.
}

.take-quiz {
  .secondary-btn {
    background-color: $white;
    border: none;
  }
}

.main-page {
  // These carousel styles are too broad but will work for now.
  .carousel {
    .slide-container {
      margin-bottom: 10px;

      .slide {
        background: none;

        .tile {
          margin: 0 auto;
        }
      }
    }

    .pager,
    button.prev,
    button.next {
      display: none;
    }
  }

  .welcome-section {
    display: flex;
    flex-direction: column;
    gap: 43px;
    @include content-container;
    padding-top: 40px;

    @include media-breakpoint-up('xl') {
      flex-direction: row;
    }

    .no-tiles {
      @include media-breakpoint-up('xl') {
        width: 734px;
      }
    }

    .ragged-layout {
      background-color: $med-grey;
      aspect-ratio: 734 / 416;
      max-height: 416px;

      @include media-breakpoint-up('xl') {
        width: 734px;
      }

      .item-container {
        position: relative;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;

        .tile.large {
          width: 100%;
          box-shadow: none;

          .board-container-main {
            width: inherit;
            margin: 0;
          }

          .cropping-window-challenge {
            img {
              object-fit: cover;
              object-position: center;
              width: 100% !important;
              height: auto !important;
              max-height: unset;
            }
          }

          .block-content.tile-content {
            flex-direction: column;
            top: 40%;
          }
        }
      }
    }

    .user-info {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 30px 32px;
      @include top-tile;

      @include media-breakpoint-up('xl') {
        order: 1;
        flex: 1;
      }

      .greetings-name {
        font-size: 36px; // Reduced from 42px to increase likelihood of staying on single line.
        line-height: 1;
      }

      .divider {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        &:after {
          content: "";
          display: block;
          @include divider(2rem);

          @include media-breakpoint-up('xl') {
            @include divider(0.5rem);
          }
        }
      }

      .rewards-section {
        @include media-breakpoint-between('md', 'xl') {
          &__item-container {
            display: flex;

            > div {
              flex-basis: 50%;
            }
          }
        }

        h2 {
          font-size: 28px;
          line-height: 1;
          text-transform: uppercase;
          margin: 0 0 30px 0;
        }

        h3 {
          font-size: 1rem;
          font-weight: normal;
          margin: 0 0 4px 0;
        }

        .value {
          font-size: 48px;
          line-height: 1;
        }

        .total-points {
          margin-bottom: 40px;

          @include media-breakpoint-up('md') {
            margin-bottom: 0;
          }

          @include media-breakpoint-up('xl') {
            margin-bottom: 20px;
          }
        }

        .tier-status {
          &__badge {
            display: flex;
            align-items: center;

            img {
              display: block;
              width: 80px;
              margin-right: 1rem;
            }
          }
        }
      }

      .detailsLink {
        font-size: 16px;
      }
    }
  }

  .graphs-section {
    @include content-container;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    #root & {
      padding-top: 0;
    }

    @include media-breakpoint-up('md') {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .graph-overview, .sales-filter-form {
        grid-column: span 2;
      }
    }

    @include media-breakpoint-up('xl') {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      .graph-overview, .sales-filter-form {
        grid-column: span 3;
      }
    }
  }

  .sales-filter-form {
    display: flex;
    width: 100%;
    max-width: 1192px;
    align-items: end;

    @media screen and (max-width: 833px) {
      margin-top: 1rem;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 768px) {
      align-items: baseline;
    }

    div#root & form.horizontalForm {
      display: flex;
      align-items: center;

      @media screen and (max-width: 833px) {
        flex-direction: column;
        align-items: baseline;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      @include media-breakpoint-up('lg') {
        width: 80%;
      }

      .form-group:not(:last-child) {
        @include media-breakpoint-up('lg') {
          margin-right: 10px;
        }
      }

      .form-group.program-year, .form-group.customer-id {
        width: 100%;

        @include media-breakpoint-up('lg') {
          width: initial;
        }

        label{
          span {
            height: 25px;

            span {
              padding-top: 11px;
            }
          }
        }
      }

      .form-group.customer-id {
        @include media-breakpoint-up('lg') {
          flex-grow: 1;
        }
      }

      .form-group.submit {
        margin: 0;
        padding-top: 0;
        border-top: none;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .button {
          margin-top: 10px;
          width: 150px;
          padding: 0;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }

  .as-of-date {
    padding-top: 20px;
    margin: 0 0 20px 10px;

    @media screen and (max-width: 768px) {
      margin: 0 0 20px 0;
      padding-left: 4px;
    }
  }

  .sales-tile {
    padding: 24px 12px 24px 12px;
    background-color: $white;
    width: 100%;
    @include top-tile;

    .salesTileHeading {
      font-size: 24px;
      margin: 15px 0px 32px 0px;
    }

    .salesTileMixHeading {
      font-size: 24px;
      margin: 0px;
    }

    .salesTileMixHeadingDSM {
      font-size: 24px;
      margin: 0px 0px 0px 15px;
    }

    .salesTileSubHeading {
      color: $black;
      font-size: 16px;

      @media screen and (max-width: 833px) {
        margin-bottom: 12px;
      }
    }
    .salesTileUnitsDSM {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0;
      color: $black
    }
    .salesTileUnitsValueDSM {
      font-size: 30px;
      margin: 0;
      color: $black
    }

    .salesTileSubHeadingDSM {
      display: flex;
      margin: 0px 15px 0px 15px;
      justify-content: space-between;
    }

    .totalDot {
      height: 12px;
      width: 12px;
      background-color: $white;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid $black;
    }

    .newDot {
      height: 12px;
      width: 12px;
      background-color: $brand-primary-color;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid $black;
    }

    .donut-chart {
      height: inherit;

      .apexcharts-canvas {
        width: 336px !important;
        height: 194px !important;

        .apexcharts-pie {
          width: 175px !important;
          height: 175px !important;
        }
      }

      .apexcharts-text.apexcharts-datalabel-label {
        font-family: $branded-font-stack !important;
        font-size: 15px !important;
      }

      .apexcharts-text.apexcharts-datalabel-value {
        font-family: $branded-font-stack !important;
        font-size: 15px !important;
      }
      .apexcharts-legends-text {
        font-family: $branded-font-stack !important;
        font-size: 22px !important;
      }

      .apexcharts-datalabels {
        font-size: 10px !important;
      }
    }

    .productType1 {
      background-color: $brand-primary-color;
    }

    .productType2 {
      background-color: $brand-secondary-color;
    }

    .productType3 {
      background-color: #CF202F;
    }

    .productType4 {
      background-color: #D1D3D4;
    }

    .productType5 {
      background-color: #FE5000;
    }

    .productType6 {
      background-color: #003DA5;
    }

    .retained {
      background-color: $brand-grey-color;
    }

    .dollars-tile-subheading-section {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
      align-items: center;

      .dollars-tile-value {
        font-size: 30px;
        margin: 0;
        color: $brand-primary-color;
      }
    }

    .bar-chart-section {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .svg-label-section {
        position: relative;
        width: 160px;
        z-index: 1;

        .svg-chart-label {
          display: flex;
          flex-wrap: wrap;
          position: absolute;
          color: $brand-primary-color;

          &::after {
            content: "";
            display: block;
            width: 360px;
            border-bottom: 1px solid #B6B6B6;

            @media screen and (max-width: 1023px) {
              max-width: 345px;
            }

            @media screen and (max-width: 833px) {
              max-width: 315px;
            }
          }

          &.chart-label-gold {
            bottom: 111px;
          }

          &.chart-label-silver {
            bottom: 62px;
          }

          &.chart-label-bronze {
            bottom: 15px;
          }

          span {
            display: inline-flex;
            justify-content: space-between;
            flex-shrink: 0;
            width: 30%;
            height: 26px;
            padding: 8px 4px 0 4px;
            font-size: 12px;
            background-color: $brand-grey-color;
          }

          .triangle {
            border-top: 26px solid transparent;
            border-left: 20px solid #B6B6B6;
          }
        }
      }

      .bar-chart-label {
        font-size: 80px;
        color: $brand-primary-color;
      }

      .numbers-display {
        color: $black;
        font-size: 16px;
        font-weight: 600;
      }

      .bar-chart {
        background-color: #CCCCCC;
        display: inline-block;
        width: inherit;
        height: 20px;
        margin-bottom: 18px;

        @media screen and (max-width: 833px) {
          margin-bottom: 14px;
        }

        .ani-0{width: 0%}.ani-1{width: 1%}.ani-2{width: 2%}.ani-3{width: 3%}.ani-4{width: 4%}.ani-5{width: 5%}.ani-6{width: 6%}.ani-7{width: 7%}.ani-8{width: 8%}.ani-9{width: 9%}.ani-10{width: 10%}.ani-11{width: 11%}.ani-12{width: 12%}.ani-13{width: 13%}.ani-14{width: 14%}.ani-15{width: 15%}.ani-16{width: 16%}.ani-17{width: 17%}.ani-18{width: 18%}.ani-19{width: 19%}.ani-20{width: 20%}.ani-21{width: 21%}.ani-22{width: 22%}.ani-23{width: 23%}.ani-24{width: 24%}.ani-25{width: 25%}.ani-26{width: 26%}.ani-27{width: 27%}.ani-28{width: 28%}.ani-29{width: 29%}.ani-30{width: 30%}.ani-31{width: 31%}.ani-32{width: 32%}.ani-33{width: 33%}.ani-34{width: 34%}.ani-35{width: 35%}.ani-36{width: 36%}.ani-37{width: 37%}.ani-38{width: 38%}.ani-39{width: 39%}.ani-40{width: 40%}.ani-41{width: 41%}.ani-42{width: 42%}.ani-43{width: 43%}.ani-44{width: 44%}.ani-45{width: 45%}.ani-46{width: 46%}.ani-47{width: 47%}.ani-48{width: 48%}.ani-49{width: 49%}.ani-50{width: 50%}.ani-51{width: 51%}.ani-52{width: 52%}.ani-53{width: 53%}.ani-54{width: 54%}.ani-55{width: 55%}.ani-56{width: 56%}.ani-57{width: 57%}.ani-58{width: 58%}.ani-59{width: 59%}.ani-60{width: 60%}.ani-61{width: 61%}.ani-62{width: 62%}.ani-63{width: 63%}.ani-64{width: 64%}.ani-65{width: 65%}.ani-66{width: 66%}.ani-67{width: 67%}.ani-68{width: 68%}.ani-69{width: 69%}.ani-70{width: 70%}.ani-71{width: 71%}.ani-72{width: 72%}.ani-73{width: 73%}.ani-74{width: 74%}.ani-75{width: 75%}.ani-76{width: 76%}.ani-77{width: 77%}.ani-78{width: 78%}.ani-79{width: 79%}.ani-80{width: 80%}.ani-81{width: 81%}.ani-82{width: 82%}.ani-83{width: 83%}.ani-84{width: 84%}.ani-85{width: 85%}.ani-86{width: 86%}.ani-87{width: 87%}.ani-88{width: 88%}.ani-89{width: 89%}.ani-90{width: 90%}.ani-91{width: 91%}.ani-92{width: 92%}.ani-93{width: 93%}.ani-94{width: 94%}.ani-95{width: 95%}.ani-96{width: 96%}.ani-97{width: 97%}.ani-98{width: 98%}.ani-99{width: 99%}.ani-100{width: 100%}

        .meter-max {
          display: inline-block;

          .currentVal {
            display: inline-block;
            animation-name: sliding-bar-animation;
            animation-duration: .5s;
            width: 100%;
            height: 20px;
          }

          @keyframes sliding-bar-animation {
            from {width: 0}
            to {width: 100%}
          }
        }
      }
    }

    .pdf-section {
      display: flex;
      margin-top: 16px;
      align-items: center;
      padding: 14px 10px;
      background-color: $brand-grey-color;

      .pdf-section-copy {
        font-size: 12px;
        color: #000000
      }

      .pdf-section-button {
        display: block;
        height: 30px;
        width: 177px;
        background-color: $brand-secondary-color;
        color: black;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        padding: 8px 0px;
      }
    }

    .legend-section-donut {
      margin: auto;
    }

    .legend-section {
      margin: 0px 0px 0px 15px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      div {
        display: flex;
        margin-bottom: 5px;
      }
    }

    .dot {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 100px;
      margin-right: 4px;
    }

    .legend {
      color: $black;
      font-size: 12px;
    }

    .label {
      flex-grow:3
    }

    .precent {
      margin-right: 10px;
    }

    .offsetGraphStyling{
      box-sizing: content-box;
    }
  }

  .infoTile {
    a {
      display: block;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .tile-section {
    background-color: white;
    position: relative;

    .ragged-layout {
      @include content-container;

      .item-container {
        position: relative;

        @include media-breakpoint-up('md') {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-gap: 20px;
        }

        @include media-breakpoint-up('xl') {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include media-breakpoint-only('sm') {
          .merchandise-marketplace-tile {
            max-width: calc(100vw - 40px);
          }
        }
      }
    }
  }
}
