.site-content > .content-wrapper > .content {
  &.activity-history,
  &.statement,
  &.pending-activities,
  &.roster-maintenance,
  &.invoice-status,
  &.processed-invoice {
    @include content-container-fluid;
  }
}

.content {
  &.activity-history,
  &.statement,
  &.pending-activities,
  &.roster-maintenance,
  &.invoice-status,
  &.processed-invoice {

    h1.content-header {
      font-size: 42px;

      @media screen and (max-width: 768px) {
        font-size: 21px;
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 34px;
      }
    }

    .button.roster {
      margin-bottom: 20px;

      @media screen and (min-width: 768px){
        width: 180px;
      }
    }

    .content-body {
      background: white;
      display: flex;
      flex-direction: column;

      .account-display-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 40px 60px;

        @media screen and (max-width: 1023px) {
          padding: 40px 20px;
          /* margin: 0 auto; */
          flex-direction: column;
          text-align: center;
        }

        .points-display {
          display: inline-block;

          .avatar {
            float: none;
            height: 140px;
            width: 140px;
            border-radius: 70px;
            display: inline-block;
            vertical-align: middle;

            .initials {
              font-size: 48px;
              border-radius: 70px;
              color: $brand-primary-color;
              background-color: $brand-secondary-color !important;
            }
          }

          .balance {
            font-size: 28px;
            vertical-align: middle;
            padding: 10px 28px;
            margin-right: 28px;
            border-right: 1px solid #d0d0d0;

            @media screen and (max-width: 1023px) {
              border-right: none;
              margin-right: 0px;
            }

            a {
              color: $brand-primary-color;
            }

            .val {
              font-size: 46px;
              color: $brand-primary-color;
            }
          }
        }

        .member-info {
          display: inline-block;
          margin-left: 12px;
          vertical-align: middle;

          @media screen and (max-width: 1023px) {
            display: block;
            margin-top: 20px;
            margin-left: 0;
          }

          .name {
            font-size: 22px;
          }
          .member-since {
            font-size: 14px;
            color: #d0d0d0;
          }
          .link {
            text-decoration: none;
            font-size: 16px;
            color: black;
            font-weight: bold;
            margin-top: 8px;
            display: block;
          }
        }

        .account-display-content-group {
          flex: 1 1 auto;
          display: flex;
          justify-content: flex-end;

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .marketplace-pending-activities-container {
          flex: 1 1 auto;
          text-align: left;
          margin-top: 20px;

          @media screen and (min-width: 768px) {
            min-width: 340px;
            max-width: 340px;
          }

          @media screen and (min-width: 1024px) {
            margin-top: 0;
          }

          .marketplace {
            .marketplace-item {
              position: relative;
              display: table;
              /* flex-direction: row;
              justify-content: space-between;
              flex-wrap: wrap; */
              margin: 0 auto;
              /* width: 45%; */

              .marketplace-item-description {
                .button {
                  margin-top: 0;
                }

                .activity-rewards {
                  display: block;
                  position: absolute;
                  width: 265px;
                  height: auto;
                  padding: 24px;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 16px;
                  border-radius: 8px;
                  background-color: $white;
                  box-shadow: 0px 4px 16px 0px rgba(52, 56, 63, 0.16);
                  z-index: 9999;
                  top: 58px;
                  left: -48px;

                  &[aria-hidden="true"] {
                    display: none;
                  }

                  .activity-reward-arrow{
                    background-color: transparent;
                  }
                  .activity-reward-arrow::before{
                      content: "";
                      position: absolute;
                      right: 129px;
                      top: -19px;
                      width: 12px;
                      height: 6px;
                      border-style: solid;
                      border-width: 10px 10px 10px 10px;
                      border-color: transparent transparent #ffffff transparent;
                      z-index: 9999;
                  }
                  .root-reward {
                    background-color: $white;

                    a {
                      display: block;
                      text-decoration: none;
                    }
                    .parent-link {
                      display: flex;
                      padding: 14px 0px;
                      align-items: center;
                      gap: 14px;
                      align-self: stretch;
                      border-radius: 8px;

                      .Merchandise-title {
                        color: $brand-primary-color;
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px; /* 150% */
                        width: 125px;
                        align-self: center;
                      }

                      .Merchandise-arrow {
                        background: url('../../../assets/img/link-external.svg');
                        width: 24px;
                        height: 24px;
                      }

                      .Experiences-title {
                        color: $brand-primary-color;
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px; /* 150% */
                        width: 125px;
                        align-self: center;
                      }

                      .Experiences-arrow {
                        background: url('../../../assets/img/link-external.svg');
                        width: 24px;
                        height: 24px;
                      }

                      .Mastery-title {
                        color: $brand-primary-color;
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px; /* 150% */
                        width: 125px;
                        align-self: center;
                      }

                      .Mastery-arrow {
                        background: url('../../../assets/img/link-external.svg');
                        width: 24px;
                        height: 24px;
                      }

                      .img {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                      }

                      @include marketplace-images;
                    }
                  }

                }




                p {
                  flex: 1 1 auto;
                  margin: 0 0 0 8px;

                  strong {
                    color: $black;
                  }
                }
              }
            }
          }

          .pending-activities {
            border-top: 1px solid $light-grey;
            margin-top: 24px;
            padding-top: 24px;

            .pending-activities-summary {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              color: $black;
              margin-bottom: 12px;

              span:first-child {
                display: inline-block;
                padding-right: 16px;
              }

              .points {
                font-weight: bold;
              }
            }

            .pending-activities-description {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }
      }

      .tabs {
        border-bottom: 1px solid #d0d0d0;
        margin: 0px 20px;
      }
      .tab {
        color: black;
        display: inline-block;
        border-left: 1px solid #d0d0d0;
        border-top: 1px solid #d0d0d0;
        border-right: 1px solid #d0d0d0;
        border-bottom: 1px solid #d0d0d0;
        width: 100%;
        height: 48px;
        margin-bottom: -1px;
        background: white;

        text-align: center;
        line-height: 48px;

        &.inactive {
          color: black;
          text-decoration: none;
          background: #e6e6e6;
        }

        @media screen and (min-width: 768px) {
          border-bottom: 1px solid white;
          width: 208px;

          &.inactive {
            border-bottom: 1px solid #d0d0d0;
          }
        }
      }
    }
  }
}

.content {
  &.activity-history {
    .content-body {
      .activity {
        margin: 0px 20px;
        padding-bottom: 38px;

        .filter {
          display: flex;
          // align-items: center;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0;
          padding: 16px;
          flex-direction: column;

          @media screen and (max-width: 1023px) {
            align-items: flex-start;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              margin: 0 0 0 10px;

              &.promotion {
                display: block !important;
              }

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              label {
                display: inline-flex;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      min-width: 60px;
                      margin-right: 0px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .date-time-input {
              display: inline-flex;
              width: 121px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 0px;
              }

              input {
                font-size: 16px;
                font-weight: normal;
                height: 36px;
                padding: 0px;
                margin: 0px 0px 0px 5px;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1023px) {
                  margin: 0px;
                  padding: 10px;
                }
              }

              .button-container {
                @media screen and (min-width: 1023px) {
                  width: 36px;
                }
                button {
                  padding: 6px 0px 2px 0px;
                }
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              width: 139px;
              max-width: 139px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                max-width: 100%;
              }
            }

            .button-container {
              display: flex;
            }
          }

          .export-buttons {
            display: flex;
            line-height: 1;
            flex-shrink: 0;
            justify-content: right;

            @media screen and (max-width: 1023px) {
              margin-top: 15px;
            }

            .pdf-export {
              display: inline-block;
              width: 24px;
              height: 24px;
              margin-left: 10px;
              background: url('../../../assets/img/pdf-export.png');
              cursor: pointer;

              @media screen and (max-width: 1023px) {
                margin-left: 0;
              }
            }
            .csv-export {
              display: inline-block;
              width: 24px;
              height: 24px;
              margin-left: 10px;
              background: url('../../../assets/img/cvs-export.png');
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.content {
  &.statement {
    .content-body {
      .statement {
        margin: 0px 20px;
        padding-bottom: 38px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .summary {
          display: inline-block;
          box-sizing: border-box;
          margin: 16px 20px 16px 0px;

          @media screen and (max-width: 1023px) {
            width: 100%;
            margin: 16px 0px 0px 0px;
          }

          .row {
            padding: 3px 4px;
            border-bottom: 1px solid #d0d0d0;
            display: flex;

            &:last-child {
              border: none;
            }

            td:first-child span {
              display: block;
              margin-right: 10px;
              flex-grow: 1;
            }

            td:last-child span {
              display: block;
              font-weight: bold;
              text-align: right;
              min-width: 50px;
            }
          }
        }

        .filter {
          flex: 1;
          display: flex;
          align-items: center;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0 auto 0;
          padding: 16px;

          @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin: 16px 0 16px 0;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              margin: 0 0px 0 10px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              label {
                display: inline-flex;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      margin-right: 0px;
                      min-width: 60px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .date-time-input {
              display: inline-flex;
              width: 121px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 0px;
              }

              input {
                font-size: 16px;
                font-weight: normal;
                height: 36px;
                padding: 0px;
                margin: 0px 0px 0px 5px;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1023px) {
                  margin: 0px;
                }
              }

              .button-container {
                button {
                  padding: 6px 0px 2px 0px;
                }
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
                flex: 1;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              width: 139px;
              max-width: 139px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                flex: 1;
              }
            }

            .button-container {
              display: flex;
              width: 36px;
            }
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 20px;
          margin: 0 auto;
          border-radius: 10px;
          // background-color: #d0d0d0;
          text-align: center;
          flex-direction: column;

          span:first-child {
            display: block;
            font-size: 20px;
          }
          span:last-child {
            display: block;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.content {
  &.pending-activities {
    .content-body {
      .pending {
        margin: 0px 20px;
        padding-bottom: 38px;

        .filter {
          display: flex;
          // align-items: center;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0;
          padding: 16px;
          flex-direction: column;

          @media screen and (max-width: 1023px) {
            align-items: flex-start;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              margin: 0 0 0 10px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              label {
                display: inline-flex;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      min-width: 60px;
                      margin-right: 0px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .date-time-input {
              display: inline-flex;
              width: 121px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 0px;
              }

              input {
                font-size: 16px;
                font-weight: normal;
                height: 36px;
                padding: 0px;
                margin: 0px 0px 0px 5px;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1023px) {
                  margin: 0px;
                }
              }

              .button-container {
                width: 36px;
                button {
                  padding: 6px 0px 2px 0px;
                }
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              width: 139px;
              max-width: 139px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                max-width: 100%;
              }
            }

            .button-container {
              display: flex;
            }
          }

          .export-buttons {
            display: inline-block;
            line-height: 1;
            flex-shrink: 0;

            @media screen and (max-width: 1023px) {
              margin-top: 15px;
            }

            .pdf-export {
              display: inline-block;
              width: 36px;
              height: 36px;
              margin-left: 10px;
              background: url('../../../assets/img/pdf-export.png');
              cursor: pointer;

              @media screen and (max-width: 1023px) {
                margin-left: 0;
              }
            }
            .csv-export {
              display: inline-block;
              width: 36px;
              height: 36px;
              margin-left: 10px;
              background: url('../../../assets/img/cvs-export.png');
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.content {
  &.roster-maintenance {
    .content-body {
      .roster {
        margin: 0px 20px;
        padding-bottom: 38px;

        .filter {
          display: flex;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0;
          padding: 16px;
          flex-direction: column;

          @media screen and (max-width: 1023px) {
            align-items: flex-start;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              display: flex;
              width: 100%;
              margin: 0 0 0 10px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              div#root & label {
                width: 100%;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;
                    padding-top: 0;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      min-width: 60px;
                      margin-right: 0px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .form-group.submit {
              justify-content: end;
              border-top: none;
              padding-top: 0;

              button {
                margin-top: 0;
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                max-width: 100%;
              }
            }

            .button-container {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.content {
  &.invoice-status,
  &.processed-invoice {
    .content-body {

      .tabs{
        margin: 0;
      }

      td[data-header='File Name'] {
        word-break: break-all;
      }

      td[data-header='Product Description'], td[data-header='Dewalt Product Description'] {
        min-width: 150px;
        word-break: break-all;
      }

      .status, .processed {
        margin: 0px 20px;
        padding-bottom: 38px;

        .filter {
          display: flex;
          position: relative;
          font-size: 16px;
          background-color: #f1f1f1;
          margin: 16px 0;
          padding: 16px;
          flex-direction: column;

          @media screen and (max-width: 1023px) {
            align-items: flex-start;
          }

          form {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1023px) {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            .form-group {
              display: flex;
              width: 100%;
              margin: 0 0 0 10px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 10px 0 0 0;
              }

              div#root & label {
                width: 100%;
                align-items: center;

                @media screen and (max-width: 1023px) {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                & > span {
                  font-size: 16px;

                  & > span {
                    margin-right: 5px;
                    padding-top: 0;

                    @media screen and (max-width: 1023px) {
                      display: block;
                      min-width: 60px;
                      margin-right: 0px;
                    }
                  }

                  & > span:after {
                    content: ':';
                  }
                }
              }
            }

            .form-group.submit {
              justify-content: end;
              border-top: none;
              padding-top: 0;

              button {
                margin-top: 0;
              }
            }

            .date-time-input {
              display: inline-flex;
              width: 121px;

              @media screen and (max-width: 1023px) {
                width: 100%;
                margin: 0px;
              }

              input {
                font-size: 16px;
                font-weight: normal;
                height: 36px;
                padding: 0px;
                margin: 0px 0px 0px 5px;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1023px) {
                  margin: 0px;
                  padding: 10px;
                }
              }

              .button-container {
                @media screen and (min-width: 1023px) {
                  width: 36px;
                }
                button {
                  padding: 6px 0px 2px 0px;
                }
              }
            }

            input,
            select {
              font-size: 16px;
              height: 36px;
              margin: 0;

              @media screen and (max-width: 1023px) {
                width: 100%;
              }
            }

            select {
              padding: 0px 40px 0px 8px;
              min-width: auto;

              @media screen and (max-width: 1023px) {
                width: 100%;
                max-width: 100%;
              }
            }

            .button-container {
              display: flex;
            }
          }
        }
      }
    }
  }
}
