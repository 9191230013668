//*******************************************************************************************/
//*********** global-defaults/buttons.scss - the starting file for global-defaults ************/
//*******************************************************************************************/

// for <a tags that were essentially buttons and may be converted to buttons. Reset button styles.
.anchor-button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;

  /* show a hand cursor on hover; some argue that we
    should keep the default arrow cursor for buttons */
  cursor: pointer;
}
*[role='button'] {
  cursor: pointer;
}
// IMPORTANT FOR ACCESSIBILITY
.anchor-button:focus {
  outline: 2px solid $focus-color;
}

.button,
a.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: $button-height;
  border: $button-border;
  border-radius: $button-border-radius;
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  color: $button-color;
  padding: $button-padding;
  text-decoration: none;
  text-align: $button-text-align;
  box-shadow: 0px 0px 0px 0px $button-background-color;
  cursor: pointer;
  transition: all 0.3s;
  background-color: $button-background-color;
  min-width: 5rem;
  width: 100%;
  margin-top: 1rem;
  text-transform: uppercase;
  @media screen and (min-width: 768px) {
    margin-left: 0.25rem;
    width: auto;
  }

  .icon {
    $size: 24px;
    $marginRight: 12px;

    fill: $button-color;
    width: $size;
    height: $size;
    margin-right: $marginRight;
  }

  &:hover {
    background-color: $button-background-color-hover;
    border: $button-border-hover;
    color: $button-color-hover;

    .icon {
      fill: $brand-secondary-color;
    }
  }
  &.appear-enabled:hover {
    // ensure there is no hover effect
    background-color: $button-background-color;
    border: $button-border;
  }
  &:focus {
    outline-color: black;
    outline-style: dotted;
    outline-width: 3px;
    outline-offset: 2px;
  }
  &.primary {
    background-color: $button-background-color;
    &:hover {
      background-color: $button-background-color-hover;
      border: $button-border-hover;
      span {
        color: $button-color-hover;
      }
    }
    &.appear-enabled:hover {
      // ensure there is no hover effect
      background-color: $button-background-color;
    }

    @media screen and (max-width: 1023px) {
      height: $button-height;
      border-radius: $button-border-radius;
      background-color: $button-background-color;
      color: $button-color;
      padding: $button-padding;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &.secondary-hover-flipped {
    color: $button-secondary-color-hover;
    background-color: $button-secondary-background-color;
    border: $button-secondary-border-hover;
    border-radius: $button-border-radius;
    text-decoration: none;
    &:hover,
    &.force-hover {
      color: $button-secondary-color;
      border: $button-secondary-border;
    }
  }
  &.secondary {
    color: $button-secondary-color;
    background-color: $button-secondary-background-color;
    border: $button-secondary-border;
    border-radius: $button-border-radius;
    text-decoration: none;
    &:hover {
      background-color: $button-secondary-background-color-hover;
      color: $button-secondary-color-hover;
      border: $button-secondary-border-hover;
    }
    &.appear-enabled:hover {
      // ensure there is no hover effect (other than the cursor changing)
      color: $button-secondary-color;
      border: $button-secondary-border;
    }
    &:not(.appear-enabled)[disabled] {
      color: $button-secondary-color-disabled;
      background-color: $button-secondary-background-color;
      border: $button-secondary-border-disabled;
      cursor: not-allowed;
      &:hover {
        background-color: $button-secondary-background-color;
        color: $button-secondary-color-disabled;
      }
    }
  }
  &:not(.appear-enabled)[disabled] {
    background-color: $button-background-color-disabled;
    color: $button-color-disabled;
    border: $button-border-disabled;
    cursor: not-allowed;
    &:hover {
      background-color: $button-background-color-disabled;
      color: $button-color-disabled;
    }
  }
  &.small {
    display: inline-block;
    padding: 6px 13px;
    height: auto;
    width: auto;
    min-width: auto;
  }
  &.nonstandard {
    text-transform: initial;
    &.close {
      &.absolute {
        position: absolute;
      }
    }
  }
}
.appear-enabled[disabled],
.appear-enabled:disabled {
  cursor: not-allowed;
}

.secondary-btn {
  @extend .button, .secondary;
}

a.secondary-btn.patched-secondary-btn {
  background-color: white;
  border: none;
  display: inline-block;
}
a.secondary-btn.patched-secondary-btn:hover {
  background-color: white;
  border: none;
}

// When in forms
form button,
form .button,
.form-group button,
.form-group .button {
  display: inline-flex;

  @media screen and (min-width: 1024px) {
    margin-right: 12px;
  }

}
