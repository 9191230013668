$overlay-color: white !default;
$overlay-background-color: rgba(0, 0, 0, 0.85) !default;
$overlay-padding: 60px !default;
$overlay-padding-mobile: 60px !default;

$overlay-header-color: $overlay-color !default;
$overlay-header-font-family: inherit !default;
$overlay-header-font-size: 40px !default;
$overlay-header-font-weight: normal !default;
$overlay-header-line-height: inherit !default;
$overlay-header-margin: 0px 0px 20px 0px !default;

$overlay-text-color: $overlay-color !default;
$overlay-text-font-family: inherit !default;
$overlay-text-font-size: 26px !default;
$overlay-text-font-weight: lighter !default;
$overlay-text-line-height: inherit !default;
$overlay-text-margin: 0px 0px 20px 0px !default;

$overlay-close-color: white !default;
$overlay-close-background-color: #737373 !default;
$overlay-close-border-style: none !default;
$overlay-close-border-width: 0px !default;
$overlay-close-border-color: transparent !default;
$overlay-close-border-radius: 17px !default;
$overlay-close-width: 34px !default;
$overlay-close-height: 34px !default;
$overlay-close-margin: 15px 15px 0px 0px !default;
$overlay-close-padding: 0px !default;

$overlay-transition-duration: 300ms !default;
$overlay-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.overlay-container {
  visibility: hidden;

  &.active {
    visibility: visible;
    min-height: 700px;
  }

  .overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    color: $overlay-color;
    background-color: $overlay-background-color;
    text-align: center;

    .content {
      padding: $overlay-padding;

      @media screen and (max-width: 1023px) {
        padding: $overlay-padding-mobile;
      }

      h1 {
        color: $overlay-header-color;
        font-family: $overlay-header-font-family;
        font-size: $overlay-header-font-size;
        font-weight: $overlay-header-font-weight;
        line-height: $overlay-header-line-height;
        margin: $overlay-header-margin;
      }

      p {
        color: $overlay-text-color;
        font-family: $overlay-text-font-family;
        font-size: $overlay-text-font-size;
        font-weight: $overlay-text-font-weight;
        line-height: $overlay-text-line-height;
        margin: $overlay-text-margin;
      }
    }

    /*
            This was done as "Browse the Marketplace" button
            (which is actually an anchor tag) on the challenge
            complete page has more text and 80% was not enough
            to accomodate it
        */
    @media screen and (max-width: 630px) {
      button,
      a {
        width: 100%;
      }
    }
    @media screen and (min-width: 631px) and (max-width: 1023px) {
      button,
      a {
        width: 80%;
      }
    }
  }

  .overlay-animate-enter {
    opacity: 0;
    transition: $overlay-transition-duration $overlay-transition-easing all;
  }

  .overlay-animate-enter.overlay-animate-enter-active {
    opacity: 1;
  }

  .overlay-animate-exit {
    opacity: 1;
    transition: $overlay-transition-duration $overlay-transition-easing all;
  }

  .overlay-animate-exit.overlay-animate-exit-active {
    opacity: 0;
  }
}
