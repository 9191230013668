$panel-header-color: #ffffff !default;
$panel-header-background-color: #595959 !default;
$panel-header-font-family: inherit !default;
$panel-header-font-size: 20px !default;
$panel-header-font-weight: normal !default;
$panel-header-line-height: inherit !default;
$panel-header-padding: 15px 15px 15px 15px !default;
$panel-margin: 1px 0px 0px 0px !default;

$panel-title-padding: 2px 15px 0px 15px !default;

$panel-badge-color: #595959 !default;
$panel-badge-background-color: #ffffff !default;
$panel-badge-font-family: inherit !default;
$panel-badge-font-size: 11px !default;
$panel-badge-font-weight: bold !default;
$panel-badge-line-height: inherit !default;
$panel-badge-padding: 5px !default;

$panel-body-color: #000000 !default;
$panel-body-background-color: #f4f4f4 !default;
$panel-body-font-family: inherit !default;
$panel-body-font-size: 16px !default;
$panel-body-font-weight: normal !default;
$panel-body-line-height: inherit !default;
$panel-body-padding: 25px 25px 25px 25px !default;

$panel-new-color: $panel-body-color !default;
$panel-new-background-color: $panel-body-background-color !default;
$panel-new-font-family: $panel-body-font-family !default;
$panel-new-font-size: $panel-body-font-size !default;
$panel-new-font-weight: bold !default;
$panel-new-line-height: inherit !default;
$panel-new-padding: 0px !default;

$panel-transition-duration: 200ms !default;
$panel-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.panel {
  margin: $panel-margin;

  // For a long time this was ::first-child which means it may not have been getting used at all
  // regardless, in centene it appears to either not or imperceptibly change things visually.
  // leaving it on since ::first-child could be interpreted by browsers like chrome and have the intended effect
  .panel:first-child {
    margin-top: 0px;
  }

  .panel-heading {
    background-color: $panel-header-background-color;

    & > button {
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      border: none;
      background: none;
      text-align: left;
      margin: 0px;
      padding: 0px;
      background-color: $panel-header-background-color;
      cursor: pointer;

      & > div {
        padding: $panel-header-padding;
        margin: 0px;
        font-size: 11px;
        display: flex;

        .panel-toggle {
          display: inline-block;
          box-sizing: border-box;
          margin: auto 0px;
          padding: 0px;
          width: 15px;
          height: 15px;
          flex-shrink: 0;

          svg {
            fill: $panel-header-color;
          }
        }

        .panel-title {
          display: inline-block;
          box-sizing: border-box;
          margin: auto 0px;
          padding: $panel-title-padding;
          color: $panel-header-color;
          font-family: $panel-header-font-family;
          font-size: $panel-header-font-size;
          font-weight: $panel-header-font-weight;
          line-height: $panel-header-line-height;
          flex: 1;

          & > p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin: 0px;
          }
        }

        .panel-badge {
          flex-shrink: 0;
          color: $panel-badge-color;
          background-color: $panel-badge-background-color;
          font-family: $panel-badge-font-family;
          font-size: $panel-badge-font-size;
          font-weight: $panel-badge-font-weight;
          line-height: $panel-badge-line-height;
          padding: $panel-badge-padding;
          margin: auto 0px;
        }
      }
    }
  }

  .panel-body {
    box-sizing: border-box;
    color: $panel-body-color;
    background-color: $panel-body-background-color;
    font-family: $panel-body-font-family;
    font-size: $panel-body-font-size;
    font-weight: $panel-body-font-weight;
    line-height: $panel-body-line-height;
    overflow: hidden;
    transition: $panel-transition-duration $panel-transition-easing max-height;

    & > div {
      margin: $panel-body-padding;

      span.new {
        color: $panel-new-color;
        background-color: $panel-new-background-color;
        font-family: $panel-new-font-family;
        font-size: $panel-new-font-size;
        font-weight: $panel-new-font-weight;
        line-height: $panel-new-line-height;
        padding: $panel-new-padding;
      }
    }

    &.collapsed {
      display: none;
    }
  }

  &.collapsed {
    .panel-body {
      max-height: 0px !important;
    }
  }
}
