.content.invoice-status {
  .modal {
    .content {
      text-align: center;
    }

    .modal-close-bar {
      background-color: $brand-primary-color;
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;

      button {
        background-color: transparent;
        padding: 1rem 0.75rem 0.75rem 1rem;
        border: none;
        cursor: pointer;

        svg {
          display: block;
          width: 24px;
          height: 24px;
          fill: $brand-secondary-color;
        }
      }
    }
  }

  .invoice-upload-img {
    padding: 0 20px $dock-height-mobile 20px;
    max-width: 100%;

    @include media-breakpoint-up('lg') {
      padding: 30px;
    }
  }
}

.site-content .content-wrapper .content.invoice-upload, .site-content .content-wrapper  .content.invoice-file {

  .form-group.input-file {

    p.error.invalid {
      display: block;
      margin-top: 10px;
    }

    .file-input {
      $file-input-border-color: #BBBBBB;

      background: white;
      border: solid;
      border-color: $file-input-border-color;
      border-width: 1px;
      border-radius: 12px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      width: 299px;
      transition: all 0.5s ease;
      position: relative;
      align-items: center;

      @include media-breakpoint-up('md') {
        width: 65%;
      }

      .input-container,
      .file {
        opacity: 1;
        transition: opacity 0.3s;
      }

      &.is-dragging {
        border-color: darken($file-input-border-color, 30%);
        background-color: $very-light-grey;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);

        .input-container,
        .file {
          opacity: 0;
        }

        .droppable-indicator {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
        }
      }

      & > label.file {
        width: 129px;
        margin-left: 1px;
        margin-right: 1px;
        text-align: center;
        flex-shrink: 0;

        .button.secondary {
          padding: .625rem 0;
        }

        transition: opacity 0.3s;
      }

      & > .input-container {
        width: 110px;
        overflow: hidden;

        @include media-breakpoint-up('md') {
          width: 100%;
        }

        span {
          display: block;
          width: 110px;
          height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;

          @include media-breakpoint-up('md') {
            width: 100%;
          }
        }
      }

      p.error {
        width: $image-input-width;
        margin-left: 1px;
        margin-right: 1px;
        margin-bottom: 10px;
      }
    }
  }
  .form-group.submit {
    text-align: left;
    border-top: none;
    padding: 15px 0px;

    .button {
      width: 129px;
    }
  }
}
