$transition-global-duration: 300ms;
$transition-global-properties: all;
$transition-global-timing-function: ease;
$transition-global: unquote(
  $transition-global-duration + ' ' + $transition-global-timing-function + ' ' + $transition-global-properties
);

$screen-breakpoints: (
  sm: 0,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px
);

// Used generally to contain content. If container object has padding, it must be added to this amount.
$content-wrapper-max-width: 1192px;

// Used by @mixin content-container to contain tiles that shouldn't be fluid as far as possible.
$breakpoint-container-width: (
  md: 788px,
  xl: 1192px
);

$breakpoint-container-padding: (
  sm: 20px,
  md: 40px,
  lg: 40px,
  xl: 40px,
  xxl: 40px
)

