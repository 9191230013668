@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
.clearfix {
  @include clearfix;
}

@mixin block {
  box-sizing: border-box;
  position: relative;
  width: $block-width;
  min-height: $block-height;
  color: $block-unlocked-color;
  background-color: $block-unlocked-background-color;
  border-color: $block-unlocked-border-color;
  border-style: $block-border-style;
  border-width: $block-border-width;
  border-radius: $block-border-radius;
  font-family: $block-font-family;
  font-size: $block-font-size;
  font-weight: $block-font-weight;
  line-height: $block-line-height;
  margin: $block-margin;
  padding: $block-padding;
  text-align: left;
}

@mixin subtle-box-shadow {
  box-shadow: 0 4px 16px 0 $box-shadow-color;
}

@mixin divider($gutter: 1.25rem) {
  margin-bottom: $gutter;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: $gutter;
}

@mixin content-container {
  @each $breakpoint, $size in $screen-breakpoints {
    $container-padding: map-get($breakpoint-container-padding, $breakpoint);
    $container-width: map-get($breakpoint-container-width, $breakpoint);

    @if $container-padding or $container-width {
      @include media-breakpoint-up($breakpoint) {
        @if $container-padding {
          padding: $container-padding;
        }

        @if $container-width {
          $padding: if($container-padding != null, $container-padding, 0px);
          max-width: calc($container-width + ($padding * 2));
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

@mixin content-container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-up('md') {
    padding-left: 40px;
    padding-right: 40px;
  }

  max-width: 1272px;
}

@mixin marketplace-images {
  .Merchandise-img {
    background: url('../../../assets/img/Merchandise_Marketplace.svg') no-repeat;
  }

  .Experiences-img {
    background: url('../../../assets/img/Experiences_Marketplace.svg') no-repeat;
  }

  .Mastery-img {
    background: url('../../../assets/img/Mastery_Marketplace.svg') no-repeat;
  }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $screen-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $screen-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $screen-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $screen-breakpoints) {
  $min:  breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max:  breakpoint-max($next, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}
