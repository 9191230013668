// ABSTRACTS
@import 'abstracts/utils';
@import 'base/fonts';
@import 'abstracts/variables';
@import 'abstracts/colors';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// VARIABLES
@import 'variables/base'; // global variables that need to be imported first
@import 'variables/init';

// BASE
@import 'base/base';
@import 'base/utils';

// LAYOUT
@import 'layout/header';
@import 'layout/footer';
@import 'layout/dock';
@import 'layout/media';
@import 'layout/form';
@import 'components/modal';
@import 'layout/nav';
@import 'layout/overlay';
@import 'layout/tile';
@import 'layout/task';
@import 'layout/promotion';
@import 'layout/meters';
@import 'layout/meters25';
@import 'layout/flash-message';

// COMPONENTS
@import 'components/accordion';
@import 'components/wizard';
@import 'components/alert';
@import 'components/carousel';
@import 'components/cookienotice';

// PAGES
@import 'pages/activity-indicator';
@import 'layout/grid';

@import 'pages/language-bar';
@import 'pages/dev-tools';
@import 'pages/welcome';
@import 'pages/login';
@import 'pages/main';
@import 'pages/forgot-password-confirmation';
@import 'pages/forgot-username';
@import 'pages/reset-password';
@import 'pages/security-code';
@import 'pages/security-question';
@import 'pages/contact-us';
@import 'pages/resources';
@import 'pages/terms-and-conditions';
@import 'pages/privacy-policy';
@import 'pages/register';
@import 'pages/profile';
@import 'pages/activity-history-statement';
@import 'pages/message-center';
@import 'pages/merch';
@import 'pages/activity-challenge';
@import 'pages/dashboard';
@import 'pages/generic-form';
@import 'pages/faqs';
@import 'pages/not-found';
@import 'pages/challenges';
@import 'pages/calendar-promotion';
@import 'pages/power-ups';
@import 'pages/invoice-upload';
@import 'global-overrides';
@import 'global-defaults/init';

:root {
  --brand-primary-color: #{$brand-primary-color};
  --brand-secondary-color: #{$brand-secondary-color};
  --brand-font-family: #{$branded-font-stack};
  --chart-color1: #{$brand-primary-color};
  --chart-color2: #{$brand-secondary-color};
  --chart-color3: #CF202F;
  --chart-color4: #D1D3D4;
  --chart-color5: #FE5000;
  --chart-color6: #003DA5;
  --progress-bar-default-color: #ccc;
  --progress-bar-active-color: #{$brand-secondary-color};
  --progress-bar-success-color: #{$brand-secondary-color};
  --screen-breakpoint-md: #{map-get($screen-breakpoints, 'md')};
  --screen-breakpoint-lg: #{map-get($screen-breakpoints, 'lg')};
  --screen-breakpoint-xl: #{map-get($screen-breakpoints, 'xl')};
  --screen-breakpoint-xxl: #{map-get($screen-breakpoints, 'xxl')};
}
